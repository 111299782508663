<template>
  <div class="container">
    <BaseHeader title="Gestão de templates"> </BaseHeader>

    <div class="container-table">
      <div class="box-total">
        <div class="total-vendas info">
          <b-form-checkbox
            v-model="status"
            @change="changeStatus"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px">
              {{ status ? " Ativas " : "Inativas" }}
            </p>
          </b-form-checkbox>
        </div>
        <div>
          <p class="total-vendas">
            Total de Templates:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>
      <div class="header-table">
        <b-col
          cols="12"
          md="4"
          class="container-pesquisa"
          :class="{ pd: isMobile }"
        >
          <div class="inputSearch">
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              type="text"
              placeholder="Pesquisar template"
              @input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
        <BaseButton
          v-if="!isMobile"
          class="nova-pagina"
          variant="primary"
          @click="createTemplate()"
          :disabled="loading"
        >
          Adicionar template
        </BaseButton>
      </div>
      <template v-if="!loading && paginas.length > 0">
        <div class="paginas-conteudo-container">
          <div
            class="paginas-conteudo"
            v-for="(item, index) in paginas"
            :key="index"
          >
            <div @click="$router.push(`editor/${item.id}`)" style="cursor: pointer">
              <img
                v-if="item.metas && getMeta(item.metas, 'image')"
                class="img-conteudo"
                :src="getMeta(item.metas, 'image')"
                alt="imagem-vazia"
                @error="setAltImg"
              />
              <img
                v-else
                class="img-conteudo"
                src="@/assets/img/icons/img_null.svg"
                alt="imagem-vazia"
              />
            </div>
            <div class="paginas-descricao">
              <div class="display-flex2">
                <h1 class="paginas-descricao-titulo">{{ item.title }}</h1>
                <div id="pagina-body" v-if="!isMobile">
                  <b-dropdown
                    id="dropPage"
                    right
                    style="margin-top: 12px"
                    v-if="status"
                  >
                    <template #button-content>
                      <img src="@/assets/img/icons/pontos.svg" alt="dots" />
                    </template>
                    <b-dropdown-item @click="$router.push(`editor/${item.id}`)"
                      >Editar</b-dropdown-item
                    >
                    <b-dropdown-item @click="clone(item.id)"
                      >Clonar Template</b-dropdown-item
                    >
                    <b-dropdown-item @click="deletePage(item)"
                      >Desativar</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    id="dropPage"
                    right
                    style="margin-top: 12px"
                    v-else
                  >
                    <template #button-content>
                      <img src="@/assets/img/icons/pontos.svg" alt="dots" />
                    </template>
                    <b-dropdown-item @click="restaurarPagina(item)"
                      >Restaurar</b-dropdown-item
                    >
                    <b-dropdown-item @click="deleteHardPage(item)"
                      >Deletar</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>
              <div class="display-flex">
                <img
                  class="img-icon"
                  src="@/assets/img/icons/hash.svg"
                  alt="icon"
                />
                <div>{{ item.id }}</div>
              </div>
              <div
                v-if="
                  item.visitas !== undefined &&
                  item.convercao !== undefined &&
                  status === true
                "
              >
                <div class="display-flex">
                  <img
                    class="img-icon"
                    src="@/assets/img/icons/raio.svg"
                    alt="icon"
                  />
                  <div>{{ item.visitas }} Visitas</div>
                </div>
                <div class="display-flex">
                  <img
                    class="img-icon"
                    src="@/assets/img/icons/estrela.svg"
                    alt="icon"
                  />
                  <div>{{ item.convercao }} Conversões</div>
                </div>
                <div
                  style="text-align: end; position: relative; top: -20px"
                  v-if="item.visitas && item.convercao"
                >
                  {{ percentage(item.convercao, item.visitas) }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <b-row
        v-if="!loading && !paginas.length === 0"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma template foi encontrada</p>
      </b-row>
      <b-row>
        <b-col>
          <Paginate
            v-if="paginas.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
            :disabled="true"
          />
        </b-col>
      </b-row>

      <div
        v-if="loading"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !paginas.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma template foi encontrada</p>
      </b-row>

      <!-- END BODY -->

      <!-- modals -->
      <EdicaoRapida
        @edicao-rapida="fetchPageListService()"
        :editPage="editPage"
      />
      <ModalNewPage
        :template="true"
      />
      <img src="../../assets/img/img_null.png" alt="imagem invisível" ref="invisible" v-show="false">
    </div>
  </div>
</template>
<script>
import TemplateListService from "@/services/resources/TemplateListService";
import TemplateService from "@/services/resources/TemplateService";

import _ from "lodash";
import Vue from "vue";

import Paginate from "@/components/Paginate";
import BaseHeader from "@/components/BaseHeader";
import BaseButton from "@/components/BaseButton";
import EdicaoRapida from "@/components/Paginas/edicaoRapida";
import RedirectService from "@/services/resources/RedirectService";
import ModalNewPage from "../Campanhas/ModalNewPage.vue";

const service = RedirectService.build();
const PaginaListService = TemplateListService.build();
const servicePage = TemplateService.build();
import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();

export default {
  name: "ListagemVendas",
  components: {
    BaseButton,
    Paginate,
    BaseHeader,
    EdicaoRapida,
    ModalNewPage
    
  },
  data() {
    return {
      seller: null,
      sellers: [],
      paginas: [],
      status: true,
      leads: [],

      search: null,
      loading: true,
      totalPages: 0,
      total: 0,
      editPage: null,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      filters: {},
      client: {
        width: 0,
      },
    };
  },
  methods: {
    createTemplate(){
      this.$bvModal.show('Modal-New-Page')
    },
    changeStatus() {
      this.pagination.currentPage = 1;
      this.fetchPageListService();
    },
    getMeta(metas, chave) {
      var result =
        metas.find((x) => x.meta_key == chave) &&
        metas.find((x) => x.meta_key == chave).meta_value
          ? metas.find((x) => x.meta_key == chave).meta_value
          : "";
      if (!result) {
        result = null;
      }
      return result;
    },
    openModalEdicao(page) {
      this.editPage = page;
      this.$bvModal.show("edicao-rapida");
    },
    clone(id) {
      servicePage.createId({ id: `clone/${id}` }).then(() => {
        this.loading = false;
        this.$grToast.toast("Página clonada com sucesso", {
          title: "Páginas",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.fetchPageListService();
      });
    },
    restaurarPagina(item) {
      Vue.swal({
        title: "Restaurar página",
        text: `Deseja restaurar a página?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Restaurar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          servicePage
            .createId({ id: `active/${item.id}` })
            .then(() => {
              this.loading = false;
              this.$grToast.toast("Página restaurada com sucesso", {
                title: "Páginas",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.fetchPageListService();
            })
            .catch(() => {
              this.loading = false;
              this.$grToast.toast("Erro ao restaurar página", {
                title: "Páginas",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    deletePage(item) {
      Vue.swal({
        title: "Desativar página",
        text: `Deseja Desativar o template?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Desativar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          servicePage
            .createId({ id: `inactive/${item.id}` })
            .then(() => {
              this.loading = false;
              this.$grToast.toast("Template desativado com sucesso", {
                title: "Páginas",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.fetchPageListService();
            })
            .catch(() => {
              this.loading = false;
              this.$grToast.toast("Erro ao deletar página", {
                title: "Páginas",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    deleteHardPage(item) {
      Vue.swal({
        title: "Deletar página",
        text: `Atenção, a ação a seguir não tem volta. Você tem certeza que deseja excluir de forma permanente a página selecionada?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          servicePage
            .destroy({ id: "delete/" + item.id })
            .then(() => {
              this.loading = false;
              this.$grToast.toast("Página deletada com sucesso", {
                title: "Páginas",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.fetchPageListService();
            })
            .catch(() => {
              this.loading = false;
              this.$grToast.toast("Erro ao deletar página", {
                title: "Páginas",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    openPageEditor(e) {
      let url =
        "https://" +
        this.$store.getters.user.blog.domain +
        "/" +
        e.post_name +
        "?fl_builder";
      window.open(url, "_blank");
    },
    setAltImg(event) {
      event.target.src = this.$refs.invisible.src;
    },
    percentage(partialValue, totalValue) {
      return ((100 * partialValue) / totalValue).toFixed(0);
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    fetchPageListService(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;
      let data = {
        page: this.pagination.currentPage,
        status: this.status ? "save" : "trash",
      };

      if (this.search) {
        data.search = this.search;
      }

      PaginaListService.search(data)
        .then((response) => {
          this.paginas = response.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchPageListService(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchPageListService(this.search);
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetchPageListService(this.search);
    }, 500),
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$store.getters.user.user.level == "seller") {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;
    this.fetchPageListService();
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
};
</script>

<style lang="scss" scoped>
.paginas-menu {
  border-radius: 10px;
  border: none;
}
.margin {
  height: 60px !important;
}
input {
  margin-bottom: 0 !important;
}
.paginas-descricao-opt {
  color: var(--greenn);
}
.paginas-descricao-titulo {
  font-weight: 600;
  font-size: 14px;
  color: var(--gray01);
}
.paginas-descricao {
  padding: 30px 30px;
}
.paginas-conteudo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.paginas-conteudo {
  border: 1px solid #ededf0;
  border-radius: 10px;
}
.img-conteudo {
  width: 100%;
  object-fit: cover;
  height: 217.42px;
  border-radius: 5px 5px 0 0;
}
.img-icon {
  width: 12px;
  height: 13.5px;
}
.display-flex2 {
  display: grid;
  grid-template-columns: 1fr 80px;
}
.display-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.nova-pagina {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  padding: 20px 40px;
  gap: 10px;
  background: var(--greenn);
  border-radius: 10px;
  border: none;
}
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}
.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
  position: relative;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  position: absolute;
  top: -27px;
  left: 0;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 20px;
  top: -5px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}

@media screen and (max-width: 1300px) {
  .search {
    left: 20px;
  }
}

.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .box-total {
    margin: 30px 0 40px 2px !important;
  }
  .header-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin-bottom: 30px !important;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
    margin-bottom: 15px;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
  .paginas-conteudo-container {
    grid-template-columns: 1fr;
  }
  .nova-pagina {
    margin: 5px;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
.inverted--black {
  filter: invert(100);
}
.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn)3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}
.pd {
  padding-right: 0;
  padding-left: 0;
}
</style>
