<template>
    <div>
        <ListaTemplates></ListaTemplates>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import ListaTemplates from '@/components/Paginas/ListaTemplates.vue';

export default {
  components: {
    BaseView,
    ListaTemplates
  },
  // created() {
  //   if (this.$store.getters.user.user.level == 'seller') {this.$router.push("/dynamicRoute/AccessDenied")};
  // },
}
</script> 